import React from 'react'
import Picture from './Picture'

const Lightbox = ({ image, visible = false, className = '' }) => {

	return (
		<div className={`Lightbox ${className} ${visible ? 'Lightbox--visible' : ''}`}>
			<div className="Lightbox__ImageContainer">
				<Picture
					className="Lightbox__Image"
					src={image.fluid.src}
					srcSet={image.fluid.srcSet}
					alt={image.alt}
					sizes="94.44vw"
				/>
			</div>
			<div className="Lightbox__Bg"></div>
		</div>
	)
}

export default Lightbox