import React, { useState, useContext, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import CardProject from '../components/CardProject'
import Lightbox from '../components/Lightbox'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SplitText from '../components/SplitText'
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import SEO from '../components/SEO'
import Picture from '../components/Picture'

const SelectedProject = ({ data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const [isLightboxVisible, setIsLightboxVisible] = useState(false);

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const project = data.prismicSelectedProject.data;
	const projectUid = data.prismicSelectedProject.uid;

	let relatedProjects = data.allPrismicSelectedProject.edges.map(relatedProject => {
		const data = relatedProject.node.data;
		return {
			uid: relatedProject.node.uid,
			image: data.image,
			title: data.title,
			location: data.location
		}
	})
	relatedProjects = relatedProjects.filter(project => project.uid !== projectUid)
	relatedProjects = relatedProjects.slice(0, 3);

	const handleOnDrawingKeyDown = e => {
		if (e.key === 'Enter') {
			setIsLightboxVisible(true)
		}
	}

	const handleCloseCursorClick = () => {
		if (isLightboxVisible) {
			setIsLightboxVisible(false)
		} else {
			navigate('/projects');
		}
	}

	return (
		<div>
			<SEO title={project.meta_title.text} description={project.meta_description.text} keywords={project.meta_keywords.text} />
			<Header currentPage="article" navigation={prismicNavigation} footer={prismicFooter} />
			<div className="SelectedProject">

				<div className="SelectedProject_Link_Container">
					<Link
						to="/projects"
						className="SelectedProject__RelatedLink"
						data-cursor-hide
					>
						❮ Back to All Projects
				</Link>
				</div>

				{project.drawing.fluid &&
					<Lightbox
						image={project.drawing}
						visible={isLightboxVisible}
						className="SelectedProject__Lightbox"
					/>
				}

				{project.image.fluid &&
					<Picture
						className="SelectedProject__MainImage"
						src={project.image.fluid.src}
						srcSet={project.image.fluid.srcSet}
						alt={project.image.alt}
						sizes="(max-width: 414px) and (min-height: 500px) 100vw, 94.44vw"
					/>
				}

				<section className="SelectedProject__Infos">
					<h1
						className="SelectedProject__Title"
						data-is-on-screen
					>
						<SplitText splitBy="lines" hasMask>
							{project.title.text}
						</SplitText>
					</h1>
					<p className="SelectedProject__Location">
						{project.location.text}
					</p>
				</section>

				<section className="SelectedProject__Details">
					<div className="SelectedProject__DetailsLeft">

						<ul className="SelectedProject__Characteristics">
							{project.characteristics.map((characteristic, index) => {
								if (characteristic.label.text.length > 0) {
									return (
										<li key={`selected-project-characteristics-item-${index}`}>
											{characteristic.label.text}: {characteristic.content.text}
										</li>
									)
								}
							})}
						</ul>

						{project.drawing.fluid &&
							<div className="SelectedProject__Drawing">
								<p className="SelectedProject__DrawingText">View Project Drawing:</p>
								<div
									className="SelectedProject__DrawingImageContainer"
									onClick={() => setIsLightboxVisible(true)}
									onKeyDown={handleOnDrawingKeyDown}
									role="button"
									tabIndex={0}
									data-cursor-hide
								>
									<Picture
										className="SelectedProject__DrawingImage"
										src={project.drawing.fluid.src}
										srcSet={project.drawing.fluid.srcSet}
										alt={project.drawing.alt}
										sizes="(max-width: 414px) and (min-height: 500px) 100vw, 16.66vw"
									/>
								</div>
							</div>
						}
					</div>

					{project.second_image.fluid &&
						<Picture
							className="SelectedProject__SecondImage"
							src={project.second_image.fluid.src}
							srcSet={project.second_image.fluid.srcSet}
							alt={project.second_image.alt}
							sizes="(max-width: 414px) and (min-height: 500px) 100vw, 69.44vw"
						/>
					}
				</section>

				<section className="SelectedProject__Images">
					{project.images.map((image, index) => {
						const data = image.image1;
						if (data.fluid === null) return false;
						return (
							<div
								className="SelectedProject__ImagesItem"
								key={`selected-project-image-${index}`}
							>
								<Picture
									className="SelectedProject__ImagesItemImage"
									src={data.fluid.src}
									srcSet={data.fluid.srcSet}
									alt={data.alt}
									sizes="(max-width: 414px) and (min-height: 500px) 100vw, 94.44vw"
								/>
								<p className="SelectedProject__ImagesItemCaption">
									{image.caption.text}
								</p>
							</div>
						)
					})}
				</section>

				<section className="SelectedProject__Related">
					<h3 className="SelectedProject__RelatedTitle">Related Projects</h3>
					<Link
						to="/projects"
						className="SelectedProject__RelatedLink"
						data-cursor-hide
					>
						Back to All Projects
					</Link>
					<ul className="SelectedProject__RelatedList">
						{relatedProjects.map((project, index) => (
							<li
								className="SelectedProject__RelatedListItem"
								key={`related-projects-${index}`}
								data-cursor-hide
							>
								<CardProject project={project} />
							</li>
						))}
					</ul>
				</section>

			</div>
			<Footer footer={prismicFooter.data} />
		</div>
	)
}

export const selectedProjectQuery = graphql`
  query selectedProjectByUid($uid: String!, $tags: [String!]) {
		prismicSelectedProject(uid: {eq: $uid}) {
			uid
			data {
				title {
					text
				}
				meta_title {
					text
				}
				meta_description {
					text
				}
				meta_keywords {
					text
				}
				location {
					text
				}
				image {
					alt
					url
					fluid {
						src
						srcSet
					}
				}
				characteristics {
					content {
						text
					}
					label {
						text
					}
				}
				drawing {
					alt
					fluid {
						src
						srcSet
					}
				}
				second_image {
					alt
					url
					fluid {
						src
						srcSet
					}
				}
				images {
					image1 {
						url
						alt
						fluid {
							src
							srcSet
						}
					}
					caption {
						text
					}
				}
			}
		}
		allPrismicSelectedProject(filter: {tags: {in: $tags}}) {
			edges {
				node {
					id
					uid
					data {
						image {
							alt
							fluid {
								src
								srcSet
							}
						}
						title {
							text
						}
						location {
							text
						}
					}
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
  }
`

export default SelectedProject;